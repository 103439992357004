import React from 'react';
import Wrapper from '../containers/wrapper';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Wrapper>
    <SEO title="Error 404" />
    <div className="container">
      <h1>404</h1>
      <p><strong>Page not found :(</strong></p>
      <p>The requested page could not be found.</p>
    </div>
  </Wrapper>
);

export default NotFoundPage;
